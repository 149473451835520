<template>
  <v-app>
    <v-main class="login-bg">
      <v-container class="fill-height">
        <v-row>
          <v-col cols="12">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "LayoutComingSoon"
};
</script>
